<template>
<div class="out">
  <div class="chat" :class="user?'':'white'">
    <div class="noLogin" v-if="!user">
      <img src="../assets/messages_img_default.png" alt="">
      <div class="btn" @click="login">立即登录</div>
    </div>
    <div class="noList" v-else-if="list.length==0">
      <img src="../assets/chat_default.png" alt="">
      <div>暂无会话</div>
    </div>
    <div class="list" v-else>
      <div class="item" v-for="(item,index) in list" @click="goChat(item.LawyerThirdId)">
        <img :src="item.MemberPersonalPic_ls" alt="" @click.stop="goDetail(item.LawyerGuid)">
        <div class="info">
          <div>{{item.MemberRealName_ls.substring(0,1)}}律师<div>{{item.lasttime}}</div>
          </div>
          <div>{{item.lastmsg}}</div>
          <div class="red" v-if="Number(item.noread)>0||item.noread=='99+'">{{item.noread}}</div>
        </div>
      </div>
    </div>
    <div class="tip" v-if="showTip">
      <img src="../assets/download_more_img.png" alt="">
      <div class="word">
        <div>建议打开或下载客户端APP</div>
        <div>以便及时查收律师回复</div>
      </div>
      <div class="btn" @click="download">立即打开</div>
      <img src="../assets/popup_icon_close3.png" alt="" @click="close">
    </div>
  </div>
  <FootBox :thistab="3"></FootBox>
  </div>
</template>
<script>
  import FootBox from '@/components/FootBox'
  import {
    myLawyersForMobileWeb
  } from '@/api'
  export default {
    name: 'chat',
    data() {
      return {
        user: null,
        list: [],
        showTip: false
      }
    },
    watch: {
      '$store.state.Chat.noreadtotal'(newVal) {
        console.log(newVal)
        this.dealsg()
      },

    },
    mounted() {
      this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
      this.isMember().then(res => {
        this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        this.AssociatorState = this.user.AssociatorState
      })
      if (this.user) {
        myLawyersForMobileWeb({
          "memberGuid": this.user.MemberGuid
        }).then(res => {
          if (res.state == 0) {
            this.list = res.data
            this.dealsg()
          }
        })
      }
      if (localStorage.getItem('zgt')) {
        this.showTip = false
      } else {
        this.showTip = this.$store.state.Chat.showTip
      }
    },
    methods: {
      dealsg() {
        var list = this.list
        for (var i = 0; i < list.length; i++) {
          if (localStorage.getItem(this.user.MemberThirdId + list[i].LawyerThirdId)) {
            var msg = JSON.parse(localStorage.getItem(this.user.MemberThirdId + list[i].LawyerThirdId))
            msg = msg[msg.length - 1]
            var lastmsg
            if (msg.msgType == 'picture') {
              lastmsg = '[图片]'
            } else if (msg.msgType == 'audio') {
              lastmsg = '[语音]'
            } else if (msg.msgType == 'file') {
              lastmsg = '[文件]'
            } else {
              lastmsg = msg.data
            }
            list[i].lastmsg = lastmsg
            list[i].lasttime = this.formatTime(Number(msg.time))
          } else {
            list[i].lastmsg = ''
            list[i].lasttime = ''
          }
          var n = localStorage.getItem(this.user.MemberThirdId + list[i].LawyerThirdId + '_noreadnum') ? localStorage
            .getItem(this.user.MemberThirdId + list[i].LawyerThirdId + '_noreadnum') : 0
          list[i].noread = n > 99 ? '99+' : n
        }
        this.list = list
        this.$forceUpdate()
      },
      download() {
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
          var loadDateTime = new Date();
          window.location = 'itms-client://?action=lawyer'
          window.setTimeout(function () { //如果没有安装app,便会执行setTimeout跳转下载页
            var timeOutDateTime = new Date();
            if (timeOutDateTime - loadDateTime < 5000) {
              window.location = "http://dl.ilvdo.com/download/akh";
            } else {
              window.close();
            }
          }, 500);
        } else {
          window.location = 'https://mob.ilvdo.com/Mobile/download.html'
        }
      },
      goDetail(guid) {
        _hmt.push(['_trackEvent', 'click', '律师头像', 'mob'])
        this.$router.push({
          path: '/lawyer?lawyerguid=' + guid
        })
      },
      close() {
        this.showTip = false
        this.$store.dispatch('onshowTip', false)
      },
      login() {
        this.$router.push('/login?to=chat')
      },
      goChat(id) {
        _hmt.push(['_trackEvent', 'click', '会话消息', 'mob'])
        this.$router.push('/chatroom?lawyerThirdId=' + id)
      },
      formatTime(timestamp) {
        let time = new Date(timestamp)
        let year = time.getFullYear()
        let month = time.getMonth() + 1
        let date = time.getDate()
        let hours = time.getHours()
        let minute = time.getMinutes()
        var thisyear = new Date().getFullYear()
        var thismonth = new Date().getMonth() + 1
        var thisdate = new Date().getDate()
        if (thisyear > year) {
          if (month < 10) {
            month = '0' + month
          }
          if (date < 10) {
            date = '0' + date
          }
          return year + '-' + month + '-' + date
        } else if (thismonth > month) {
          if (month < 10) {
            month = '0' + month
          }
          if (date < 10) {
            date = '0' + date
          }
          return month + '-' + date
        } else if (thisdate > date) {
          if (month < 10) {
            month = '0' + month
          }
          if (date < 10) {
            date = '0' + date
          }
          return month + '-' + date
        } else if (thisdate == date) {
          if (hours < 10) {
            hours = '0' + hours
          }
          if (minute < 10) {
            minute = '0' + minute
          }
          return hours + ':' + minute
        }
      },
    },
    components: {
      FootBox,
    }
  }

</script>

<style scoped lang="scss">
  .noLogin {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -223px;
    margin-top: -350px;

    img {
      width: 446px;
      margin-bottom: 20px;
    }

    .btn {
      width: 247px;
      height: 87px;
      border: 2px solid #21D0C8;
      border-radius: 44px;
      font-size: 30px;
      color: #21D0C8;
      text-align: center;
      line-height: 87px;
      margin: 0 auto;
      box-sizing: border-box;
    }
  }

  .noList {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -110px;
    margin-top: -222px;

    img {
      width: 228px;
      margin-bottom: 20px;
    }

    div {
      font-size: 24px;
      color: #666;
      text-align: center;
    }
  }
.out{
  position: absolute;
    width: 100%;
    height: calc(100% - 98px);
    top: 0;
    left: 0;
    overflow: auto;
}
  .chat {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #F5F5F8;
  }

  .white {
    background: #fff;
  }

  .list {
    background: #FFFFFF;
    // margin-bottom: 100px;
    width: 100%;
    max-height: calc(100% - 98px);
    overflow: auto;

    .item {
      padding: 23px 0 0 29px;
      display: flex;

      >img {
        width: 100px;
        height: 100px;
        margin-right: 18px;
        border-radius: 50%;
      }

      .info {
        flex: 1;
        padding: 10px 30px 36px 0;
        border-bottom: 1px solid #EAEAEA;
        position: relative;

        >div:nth-child(1) {
          font-size: 32px;
          color: #333333;
          margin-bottom: 6px;

          div {
            float: right;
            font-size: 24px;
            color: #666666;
            position: relative;
            top: 8px;
          }
        }

        >div:nth-child(2) {
          font-size: 26px;
          color: #666666;
          height: 30px;
          width: 480px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .red {
          width: 34px;
          height: 34px;
          background: #FD5252;
          border-radius: 50%;
          font-size: 24px;
          color: #FFFFFF;
          text-align: center;
          line-height: 36px;
          position: absolute;
          bottom: 32px;
          right: 30px;
        }
      }
    }

    .item:last-child .info {
      border: 0;
    }
  }

  .tip {
    width: 690px;
    height: 120px;
    background: rgba(51, 51, 51, 0.7);
    border-radius: 20px;
    box-sizing: border-box;
    padding: 30px;
    position: fixed;
    bottom: 140px;
    left: 50%;
    margin-left: -345px;

    >img:nth-child(1) {
      width: 97px;
      position: absolute;
      top: -73px;
      left: 30px;
    }

    .word {
      font-size: 24px;
      color: #FFFFFF;
    }

    .btn {
      width: 140px;
      height: 50px;
      background: #21D0C8;
      border-radius: 25px;
      font-size: 24px;
      color: #FFFFFF;
      text-align: center;
      line-height: 54px;
      position: absolute;
      top: 36px;
      right: 86px;
    }

    >img:last-child {
      width: 26px;
      position: absolute;
      top: 48px;
      right: 20px;
    }
  }

</style>
<style>


</style>
