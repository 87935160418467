<template>
  <div class="FootBox">
    <div class="item" :class="tab==1?'active':''" @click="change(1,'index','首页')">
      <img
        :src="tab==1?require('../assets/taber_icon_home_selected.png'):require('../assets/taber_icon_home_normal.png')"
        alt="">
      <div>首页</div>
    </div>
    <div class="item" :class="tab==2?'active':''" @click="change(2,'recommend','律师')">
      <img
        :src="tab==2?require('../assets/taber_icon_find_selected.png'):require('../assets/taber_icon_find_normal.png')"
        alt="">
      <div>找律师</div>
    </div>
    <div class="item" :class="tab==3?'active':''" @click="change(3,'chat','消息')">
      <div class="msg">
        <img
          :src="tab==3?require('../assets/taber_icon_chat_selected.png'):require('../assets/taber_icon_chat_normal.png')"
          alt="">
        <div class="noread" v-if="user&&Number(msgNum)>0">{{msgNum}}</div>
      </div>
      <div>消息</div>
    </div>
    <div class="item" :class="tab==4?'active':''" @click="change(4,'center','我的')">
      <img :src="tab==4?require('../assets/taber_icon_me_selected.png'):require('../assets/taber_icon_me_normal.png')"
        alt="">
      <div>我的</div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'FootBox',
    props: ['thistab'],
    data() {
      return {
        tab: this.thistab,
        msgNum: 0,
        user: null
      }
    },
    watch: {
      '$store.state.Chat.noreadtotal'(newVal) {
        console.log(newVal)
        this.msgNum = Number(newVal)
      }
    },
    mounted() {
      if (localStorage.getItem('user')) {
        this.user = JSON.parse(localStorage.getItem('user'))
        this.msgNum = localStorage.getItem(this.user.MemberThirdId + '_noreadtotal') ? localStorage.getItem(this.user
          .MemberThirdId + '_noreadtotal') : 0
      }
    },
    methods: {
      change(num, path, name) { //跳转页面
        _hmt.push(['_trackEvent', 'click', name, 'mob']);
        this.tab = num
        this.$router.push('/' + path)
      }
    },
    components: {

    }
  }

</script>

<style scoped lang="scss">
  .FootBox {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: #fff;
    height: 98px;
    width: 100%;
    box-sizing: border-box;
    padding-top: 10px;
    border-top: 1px solid #E5E5E5;

    .item {
      flex: 1;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #5D6069;

      img {
        width: 46px;
      }
    }

    .item.active {
      color: #21D0C8;
    }

    .msg {
      position: relative;
      width: 46px;
      height: 46px;
      margin: 0 auto;
      margin-bottom: 5px;
    }

    .noread {
      width: 30px;
      height: 30px;
      background: #F24537;
      border: 2px solid #FFFFFF;
      border-radius: 50%;
      font-size: 16px;
      color: #FFFFFF;
      position: absolute;
      top: -8px;
      right: -15px;
      text-align: center;
      line-height: 32px;
    }
  }

</style>
<style>


</style>
